<template>
    <div>
        <b-button variant="primary" size="sm" class="float-right" @click="$bvModal.show(`add-adv-modal`)">
            <i class="ri-add-box-line "></i>
            Create New Advertiser
        </b-button>

        <b-modal :id="`add-adv-modal`" title="Add ADvertiser" hide-footer>
            <form @submit.prevent="addAdvertiser">


                <div class="row">
                    <div class="col-3">
                        <FormulateInput name="country_code" type="number" label="Country code"
                            v-model="form.country_code" placeholder="Country code" />
                    </div>
                    <div class="col-9">
                        <FormulateInput v-model="form.phone_number" name="phone_number" type="number"
                            label="Phone number" placeholder="Phone number" />
                    </div>
                </div>

                <div class="form-group mb-0 mt-2">
                    <div class="d-flex justify-content-end">
                        <b-button type="submit" variant="primary" size="sm">
                            <span v-if="submitting">Submitting...</span>
                            <span v-else> Submit </span>
                        </b-button>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { advertiser } from "@/config/api/advertiser";


export default {
    data() {
        return {
            form: {
                country_code: "966",
                phone_number: "",
            },
            submitting: false,
            submit: false,

        };
    },
    validations: {
        form: {
            country_code: { required },
            phone_number: { required },
        },
    },
    methods: {
        addAdvertiser() {
            this.submit = true; this.$v.$touch(); if (!this.$v.$invalid) {
                const api = advertiser.addAdvertiser;
                this.form.user_id = this.data;
                api.data = this.form;
                this.submitting = true;
                this.generateAPI(api)
                    .then((res) => {
                        this.$bvToast.toast("Advertiser added successfully!", {
                            title: "Create",
                            variant: "success",
                            solid: true,
                        });
                        this.$v.$reset();
                        this.form = {
                            file: [],
                            name: "",
                            trade_license_number: "",
                        };
                        this.$emit("change", res.data.company);
                        this.$bvModal.hide("add-adv-modal");
                    })
                    .catch((err) => {
                        this.$bvToast.toast(
                            `Advertiser is not added, ${err.response.data.error}!`,
                            {
                                title: "Create",
                                variant: "danger",
                                solid: true,
                            }
                        );
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            }
        }
    }
}
</script>